<template>
  <div class="home">
    <!-- 首页 -->
    <!-- 整体行业定制方案，驱动企业互联网化 -->
    <div v-if="$store.state.isPhone" class="phone internet">
      <div class="WebMain">
        <p class="DivTitle">WHAT WE DO</p>
        <div class="DivContent">
          <div class="item  wow slideInLeft" v-for="item in internetList" :key="item.id">
            <div class="img">
              <!-- <img class="imgPath" :src="require(`@/assets/img/home/${item.imgPath}`)" alt=""> -->
              <img class="hoverPath" :src="require(`@/assets/img/home/${item.hoverPath}`)" alt="">
              <p class="title p22">{{ item.title }}</p>
            </div>
            
            <p class="content p17">{{ item.content }}</p>
            <p class="bottomStyle"></p>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="internet">
      <div class="WebMain">
        <p class="DivTitle">WHAT WE DO</p>
        <div class="DivContent">
          <div class="item slideInLeft" v-for="item in internetList" :key="item.id">
            <div class="img">
              <img class="imgPath" :src="require(`@/assets/img/home/${item.imgPath}`)" alt="">
              <img class="hoverPath" :src="require(`@/assets/img/home/${item.hoverPath}`)" alt="">
            </div>
            <p class="title p22">{{ item.title }}</p>
            <p class="content p17">{{ item.content }}</p>
            <p class="bottomStyle"></p>
          </div>
        </div>
      </div>
    </div>
    <!-- 打造行业内领先技术服务化 -->
    <div class="service">
      <div class="WebMain">
        <p class="DivTitle">Building leading technology services</p>
        <div class="DivContent">
          <div class="item" v-for="item in serviceList" :key="item.id">
            <div class="img">
              <img class="imgPath" v-if="!item.imgPath" src="@/assets/img/home/service (1).png" alt="">
              <img v-else :src="require(`@/assets/img/home/${item.imgPath}`)" alt="">
            </div>
            <p class="title p24">{{ item.title }}</p>
            <p class="content p16">{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 专注软件定制开发，多项自主知识产权 -->
    <div class="development">
      <div class="WebMain">
        <p class="DivTitle">multiple independent intellectual property rights and successful cases</p>
        <div v-if="!$store.state.isPhone" class="DivContent">
          <div class="item" v-for="item in developmentList" :key="item.id">
            <div class="itemContent">
              <div class="top">
              </div>
              <div class="num">
                <p class="p54">{{ item.num }} <span class="span22">{{ item.symbol }}</span> </p>
              </div>
              <p class="title p22">{{ item.title }}</p>
              <img :src="require(`@/assets/img/home/${item.imgPath}`)" alt="">
              <div class="bottom">
              </div>
            </div>
          </div>
        </div>
        <div v-else class="phone">
          <el-carousel :interval="10000" indicator-position="outside">
            <el-carousel-item v-for="item in developmentList" :key="item.id">
              <div class="container">
                <div class="item">
                  <div class="itemContent">
                    <div class="top">
                    </div>
                    <div class="num">
                      <p class="p54">{{ item.num }} <span class="span22">{{ item.symbol }}</span> </p>
                    </div>
                    <p class="title p22">{{ item.title }}</p>
                    <img :src="require(`@/assets/img/home/${item.imgPath}`)" alt="">
                    <div class="bottom">
                    </div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="number">
      <div class="WebMain">
        <p class="DivTitle">Assist enterprises in Digital transformation</p>
        <div class="DivContent newsContent">
          <div class="item" @click="toDetail(item.id)" v-for="item in newsList" :key="item.id">
            <div class="img">
              <span class="time">{{ (item.created).slice(0, 10) }}</span>
              <img v-if="item.thumbnail" :src="$store.state.requestAPI + item.thumbnail" alt="">
              <img v-else src="../../assets/img/home/service (3).png" alt="">
            </div>
            <div class="summary">
              <div class="leftStyle"></div>
              <p class="title">{{ item.title }}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { articleListAPI } from '@/api/article/EnglishArticle'
import WOW from 'wow.js'
export default {
  data() {
    return {
      internetList: [
        { id: 1, title: 'Customized APP/Mini Program Development', content: 'Our software development services cover areas such as lifestyle services, live broadcasting, social media, healthcare, education, real estate, tourism, automobile, property management, and enterprise solutions. We provide clear development solutions according to your specific needs, and create a unique APP just for you', imgPath: 'Internet (1).png', hoverPath: 'Internet (5).png' },
        { id: 2, title: 'Website Construction', content: 'We offer customized website construction for various enterprises and individuals, including corporate websites, portal sites, industry websites, and more. We enable companies to be connected through the internet, and provide unique branding solutions to promote industry transformation and upgrading.', imgPath: 'Internet (2).png', hoverPath: 'Internet (6).png' },
        { id: 3, title: 'Enterprise Management Systems', content: 'We provide customized internal management systems for corporations including CRM, OA, ERP, and other end-to-end solutions. We promote office automation, business intelligence, and industry intelligence to help you stay ahead of the curve.', imgPath: 'Internet (3).png', hoverPath: 'Internet (7).png' },
        { id: 4, title: 'Data Analysis System', content: 'Our data analysis systems are based on multidimensional, big-data visualization analysis, covering all data sources and breaking data silos. We provide multi-dimensional insights, real-time comprehensive analysis reports, and decision-making support for your business.', imgPath: 'Internet (4).png', hoverPath: 'Internet (8).png' },
      ],
      serviceList: [
        { id: 1, title: 'Agile development', content: 'Uses Agile development methodology that emphasizes customer demands and follows iterative approaches for development.', imgPath: 'service (1).png' },
        { id: 2, title: 'Code standardization', content: 'We enforce strict code quality management to ensure stable and reliable products.', imgPath: 'service (2).png' },
        { id: 3, title: 'Quality assurance', content: 'GB/T19001/ISO001 software quality management system certification.', imgPath: 'service (3).png' },
      ],
      developmentList: [
        { id: 1, title: 'intellectual rights', num: 10, symbol: '+', imgPath: 'development (1).png' },
        { id: 2, title: 'successful cases', num: 30, symbol: '+', imgPath: 'development (2).png' },
        { id: 3, title: 'after-sales guarantee', num: 100, symbol: '%', imgPath: 'development (3).png' },
        { id: 4, title: 'development experience', num: 5, symbol: 'Years', imgPath: 'development (4).png' },
      ],
      newsList: [

      ]

    }
  },
  methods: {
    // 获取新闻列表
    async getNewsList() {
      let info = { pageNum: 1, pageSize: 4, status: 'normal', categoryId: 11 }
      const res = await articleListAPI(info);
      console.log(res);
      if (res.code === 200) {
        this.newsList = res.data.list
      }
    },
    // 跳转至详情
    toDetail(val) {
      this.$router.push(`/trends/detail/${val}`)
    }
  },
  created() {
    this.getNewsList();
  },
  mounted() {
    var wow = new WOW({
      boxClass: 'wow',
      offset: 10,
      mobile: true,
      live: true
    });
    wow.init();
  }
}
</script>

<style lang="scss" scoped>
.home {
  .WebMain {


    .DivContent {
      display: flex;
      flex-wrap: wrap;
      // justify-content: center;

      .item {
        .title {
          color: #222222;
        }

        .content {
          color: #666666;
        }
      }
    }
  }


  @keyframes bottomStyle {
    from {
      width: 0px;
    }

    to {
      width: 100%;
    }
  }



  .phone.internet .WebMain .DivContent .item {
    margin-bottom: 20px;

    .title {
      display: inline-block;
      margin-top: 0;
      margin-left: 20px;
      line-height: 35px;
      // text-align: center;
      color: var(--custom-color);
    }

    .img {
      display: inline-block;
      display: flex;
      // min-height: 60px;
      height: fit-content;
      // position: absolute;
      // left: 10px;
      // text-align: center;
      img{
        height: 40px;
      }
    }
    .hoverPath{
      display: block;
    }
    .imgPath{
      display: none;
    }
  }

  .internet {
    background: url('../../assets/img/home/bg1.jpg');
    background-size: cover;

    .WebMain {
      .DivContent {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: center;

        .item {
          width: 550px;
          margin-bottom: 60px;
          position: relative;

          .img {
            height: 46px;

            img {
              height: 100%;
            }

            // width: 46px;
          }

          &:hover {

            .title,
            .content {
              color: var(--custom-color);
            }

            .hoverPath {
              display: block;
              // height: 46px;
            }

            .imgPath {
              display: none;
            }

            .bottomStyle {
              animation-name: bottomStyle;
              animation-duration: 0.8s;
              animation-fill-mode: both;
            }

          }





          .hoverPath {
            display: none;
          }

          .title {
            margin-top: 34px;
            margin-bottom: 10px;
          }

          .content {
            line-height: 27px;
            padding-bottom: 20px;
          }

          .bottomStyle {
            width: 0;
            height: 2px;
            position: absolute;
            bottom: 0;
            // margin-top: 20px;
            background: var(--custom-color);
          }
        }
      }
    }
  }

  .service {
    padding-bottom: 60px;

    .WebMain {
      .DivContent {

        // justify-content: flex-start;
        .item {
          width: 380px;
          min-height: 300px;
          margin-right: 20px;
          padding-bottom: 6px;

          // border-bottom: 1px solid #666666;
          // margin-bottom: 40px;
          &:last-child {
            margin-right: 0px;
          }

          &:hover {
            padding-bottom: 0;
            p{
              // color: transparent;
              display: none;
            }
            .img {
              height: 100%;
              // overflow: visible;
              transition: all 0.8s ;
              img {
                width: 100%;
                // z-index: 2;
              }
            }
          }

          .img {
            height: 150px;
            overflow: hidden;

            img {
              width: 100%;
            }
          }

          .title {

            font-weight: 600;
            margin-top: 12px;
            margin-bottom: 8px
          }

          .content {

            line-height: 25px;
          }
        }
      }
    }
  }

  .development {
    background: url('../../assets/img/home/bg2.jpg');
    background-size: cover;
    padding-bottom: 60px;

    .WebMain {
      .DivTitle {
        color: white;
      }

      .DivContent {
        .item {
          width: 280px;
          height: 446px;
          background: #07182A;
          color: white;
          opacity: 0.8;
          margin-right: 20px;
          text-align: center;
          // padding: 25px 30px;
          box-sizing: border-box;
          margin-bottom: 10px;

          &:hover {

            .top,
            .bottom {

              &:before,
              &:after {
                transition: all .3s;
                background: url('https://test2.zhihanyixing.com/api/image/7c200b8c1fa047f298aa116e22c25bf37c200b8c1fa047f298aa116e22c25bf3.png') no-repeat;
                background-size: contain;
                width: 21px;
                height: 21px;
                // transform: translateY(-20px);

              }


            }

            .top {
              &:before {
                transform: translate(-20px, -15px);
              }

              &:after {
                transform: translate(20px, -15px) rotate(90deg);
              }
            }

            .bottom {
              &:before {
                transform: translate(-20px, 25px) rotate(270deg);
              }

              &:after {
                transform: translate(20px, 25px) rotate(180deg);
              }
            }
          }

          .num {
            padding-top: 100px;
            margin-bottom: 13px;

            p {
              font-weight: 600;

              span {
                font-weight: 500;
                position: absolute;
              }
            }
          }

          .title {
            font-weight: 500;
            line-height: 30px;
            margin-bottom: 30px;
          }

          .top,
          .bottom {
            position: absolute;
            width: 280px;
            height: 30px;
            box-sizing: border-box;

            &:before,
            &:after {
              content: '';
              display: inline-block;
              background: url('https://test2.zhihanyixing.com/api/image/5086bf3de2d841d390a9f01104f8fb3f5086bf3de2d841d390a9f01104f8fb3f.png') no-repeat;
              background-size: contain;
              width: 21px;
              height: 21px;
              position: absolute;
              // transition: all .3s;
            }

            &:before {
              left: 38px
            }

            &:after {
              right: 38px
            }
          }

          .bottom {
            bottom: 45px;

            &:after {
              transform: rotate(180deg);
            }

            &:before {
              transform: rotate(270deg);
            }
          }

          .top {
            top: 45px;

            &:after {
              transform: rotate(90deg);
            }
          }




          // .top{
          //   top: 25px;
          //   display: flex;
          //   justify-content: space-between;
          //   .topRight{
          //     float: right;
          //   }
          // }
          // .bottom{
          //   bottom: 25px;
          // }

          &:last-child {
            margin-right: 0;
          }

          .title {
            color: white;
          }

          img {
            width: 90px;
            height: 90px;
          }
        }

        .itemContent {
          position: relative;
          width: 100%;
          height: 100%;
        }
      }

      .phone {
        .container {
          height: 100%;
        }

        background: #07182A;
        opacity: 0.8;

        .itemContent {
          position: relative;
          width: 100%;
          height: 100%;
        }

        .item {
          text-align: center;
          height: 100%;
          // padding: 25px 30px;
          box-sizing: border-box;
          margin-bottom: 10px;
          color: white;

          img {
            width: 90px;
            height: 90px;
          }

          &:hover {

            .top,
            .bottom {

              &:before,
              &:after {
                transition: all .3s;
                background: url('https://test2.zhihanyixing.com/api/image/7c200b8c1fa047f298aa116e22c25bf37c200b8c1fa047f298aa116e22c25bf3.png') no-repeat;
                background-size: contain;
                width: 21px;
                height: 21px;
                // transform: translateY(-20px);

              }


            }

            .top {
              &:before {
                transform: translate(-20px, -15px);
              }

              &:after {
                transform: translate(20px, -15px) rotate(90deg);
              }
            }

            .bottom {
              &:before {
                transform: translate(-20px, 25px) rotate(270deg);
              }

              &:after {
                transform: translate(20px, 25px) rotate(180deg);
              }
            }
          }

          .num {
            padding-top: 100px;
            margin-bottom: 13px;

            p {
              font-weight: 600;

              span {
                font-weight: 500;
                position: absolute;
              }
            }
          }

          .title {
            font-weight: 500;
            line-height: 30px;
            margin-bottom: 30px;
          }

          .top,
          .bottom {
            position: absolute;
            width: 100%;
            height: 30px;
            box-sizing: border-box;

            &:before,
            &:after {
              content: '';
              display: inline-block;
              background: url('https://test2.zhihanyixing.com/api/image/7c200b8c1fa047f298aa116e22c25bf37c200b8c1fa047f298aa116e22c25bf3.png') no-repeat;
              background-size: contain;
              width: 21px;
              height: 21px;
              position: absolute;
              // transition: all .3s;
            }

            &:before {
              left: 38px
            }

            &:after {
              right: 38px
            }
          }

          .bottom {
            bottom: 25px;

            &:after {
              transform: rotate(180deg);
            }

            &:before {
              transform: rotate(270deg);
            }
          }

          .top {
            top: 45px;

            &:after {
              transform: rotate(90deg);
            }
          }

        }

        ::v-deep .el-carousel__container {
          height: 440px !important;
          z-index: 0 !important;

          // .el-carousel__arrow {
          //   top: 72% !important;
          //   background-color: white;
          //   color: #565656;
          //   font-size: 26px;
          // }

        }

      }
    }
  }

  .number {
    background: url('../../assets/img/home/bg3.jpg');
    padding-bottom: 60px;


  }
}

@media screen and (min-width:1180px) {
  .home {
    .internet {
      .WebMain {
        .item {

          &:nth-child(2),
          &:nth-child(4) {
            margin-left: 60px;
          }
        }
      }
    }

    // .development .WebMain .DivContent .item{
    //   margin-bottom: 10px;

    // }

    .number .WebMain .newsContent .item {
      margin-right: 0px;
    }
  }
}

@media screen and (max-width:1180px) {
  .home {
    .development .WebMain .DivContent .item {
      margin-bottom: 10px;
      margin-right: 20px !important;
    }

    .service .WebMain .DivContent .item {
      margin-bottom: 40px;
    }

  }
}

@media screen and (max-width: 651px) {
  // .home .internet .WebMain .DivContent .item .title[data-v-1f8323f4]

  .home .development .WebMain .DivContent .item {
    margin-right: 0px !important;
  }

  .home .service .WebMain .DivContent .item {
    margin-right: 20px !important;

  }
}</style>